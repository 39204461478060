// components/SideTabContext.js
import React, { createContext, useContext, useState } from 'react';

const SideTabContext = createContext();

export const SideTabProvider = ({ children }) => {
  const [isSideTabVisible, setSideTabVisibility] = useState(false);

  const toggleSideTab = () => {
    setSideTabVisibility(!isSideTabVisible);
  };

  return (
    <SideTabContext.Provider value={{ isSideTabVisible, toggleSideTab }}>
      {children}
    </SideTabContext.Provider>
  );
};

export const useSideTab = () => {
  return useContext(SideTabContext);
};
