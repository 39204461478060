exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-components-about-us-js": () => import("./../../../src/pages/about-us/components/about-us.js" /* webpackChunkName: "component---src-pages-about-us-components-about-us-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-home-components-about-js": () => import("./../../../src/pages/home/components/about.js" /* webpackChunkName: "component---src-pages-home-components-about-js" */),
  "component---src-pages-home-components-landing-js": () => import("./../../../src/pages/home/components/landing.js" /* webpackChunkName: "component---src-pages-home-components-landing-js" */),
  "component---src-pages-home-components-our-work-js": () => import("./../../../src/pages/home/components/our-work.js" /* webpackChunkName: "component---src-pages-home-components-our-work-js" */),
  "component---src-pages-home-components-quote-us-js": () => import("./../../../src/pages/home/components/quote-us.js" /* webpackChunkName: "component---src-pages-home-components-quote-us-js" */),
  "component---src-pages-home-components-reviews-js": () => import("./../../../src/pages/home/components/reviews.js" /* webpackChunkName: "component---src-pages-home-components-reviews-js" */),
  "component---src-pages-home-components-services-js": () => import("./../../../src/pages/home/components/services.js" /* webpackChunkName: "component---src-pages-home-components-services-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-services-components-our-services-js": () => import("./../../../src/pages/our-services/components/our-services.js" /* webpackChunkName: "component---src-pages-our-services-components-our-services-js" */),
  "component---src-pages-our-services-index-js": () => import("./../../../src/pages/our-services/index.js" /* webpackChunkName: "component---src-pages-our-services-index-js" */),
  "component---src-templates-service-details-js": () => import("./../../../src/templates/service-details.js" /* webpackChunkName: "component---src-templates-service-details-js" */)
}

